import { getFooterLinks } from 'app/core/components/Footer/Footer';

import { BrandingSettingsDTO, ConfigData } from './types';

export const defaultSettings: BrandingSettingsDTO = {
  loginLogo: '',
  loginTitle: '',
  loginSubtitle: '',
  loginBackground: '',
  loginBoxBackground: '',
  footerLinks: null,
  appTitle: '',
  favIcon: '',
  menuLogo: '',
  loadingLogo: '',
  appleTouchIcon: '',
};

export const dtoToConfig = (dto: BrandingSettingsDTO): ConfigData => {
  const dtoLinks = dto.footerLinks;
  return {
    ...dto,
    footerLinks: !!dtoLinks?.length ? dtoLinks : getFooterLinks(),
  };
};
