import { css, cx } from '@emotion/css';
import React from 'react';

import { config } from '@grafana/runtime';
import { styleMixins } from '@grafana/ui';
import { Branding, BrandComponentProps } from 'app/core/components/Branding/Branding';
import { setFooterLinksFn } from 'app/core/components/Footer/Footer';

import { getBrandingSettings } from './api';
import { BrandingSettingsDTO } from './types';
import { getResourcePath, isCSSRule } from './utils/content';
import { processFooterLinks } from './utils/footerLinks';

export async function initCustomBranding() {
  const settings: BrandingSettingsDTO = await getBrandingSettings();
  if (!settings) {
    return;
  }

  Branding.LoginTitle = 'Welcome to Grafana Enterprise';

  const links = settings.footerLinks;
  if (links?.length) {
    setFooterLinksFn(() => processFooterLinks(links));
  }

  if (settings.appTitle) {
    Branding.AppTitle = settings.appTitle;
  }

  const logoSettings = settings.loginLogo;
  if (logoSettings) {
    const src = getResourcePath(logoSettings);
    Branding.LoginLogo = (props: BrandComponentProps) => (
      <img
        className={cx(
          props.className,
          css`
            max-width: 150px;
            max-height: 250px;
            width: auto;

            @media ${styleMixins.mediaUp(config.theme.breakpoints.sm)} {
              max-width: 250px;
            }
          `
        )}
        src={src}
      />
    );
    Branding.LoginLogo.displayName = 'BrandingLoginLogo';

    // Reset these to not break existing login screens
    Branding.LoginTitle = '';
    Branding.GetLoginSubTitle = () => '';
  }

  if (settings.loginTitle) {
    Branding.LoginTitle = settings.loginTitle;
  }

  if (settings.loginSubtitle) {
    Branding.GetLoginSubTitle = () => settings.loginSubtitle;
  }

  const menuLogo = settings.menuLogo;
  if (menuLogo) {
    const src = getResourcePath(menuLogo);
    Branding.MenuLogo = (props: BrandComponentProps) => <img className={props.className} src={src} />;
    Branding.MenuLogo.displayName = 'BrandingMenuLogo';
  }

  const loginBackground = settings.loginBackground;
  if (loginBackground) {
    const url = getResourcePath(loginBackground);
    const background = css`
      background: ${isCSSRule(loginBackground) ? loginBackground : `url(${url})`};
      background-size: cover;
    `;

    Branding.LoginBackground = (props: BrandComponentProps) => (
      <div className={cx(background, props.className)}>{props.children}</div>
    );
    Branding.LoginBackground.displayName = 'BrandingLoginBackground';
  }

  const loginBoxBackground = settings.loginBoxBackground;
  if (loginBoxBackground) {
    const url = getResourcePath(loginBoxBackground);
    const background = css`
      background: ${isCSSRule(loginBoxBackground) ? loginBoxBackground : `url(${url})`};
      background-size: cover;
    `;

    Branding.LoginBoxBackground = () => background;
  }
}
