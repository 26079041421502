import { AppEvents } from '@grafana/data';
import { getBackendSrv } from '@grafana/runtime';
import { appEvents } from 'app/core/core';

export const ROOT = '/api/custom-branding/';

export const getBrandingSettings = () => {
  return getBackendSrv().get(ROOT);
};

export const saveBrandingSettings = (data: FormData, callback: Function) => {
  return fetch(ROOT, { method: 'POST', body: data }).then((response) => {
    if (response.ok) {
      callback();
      appEvents.emit(AppEvents.alertSuccess, ['Successfully saved configuration']);
      window.location.reload();
    } else {
      appEvents.emit(AppEvents.alertError, ['Error saving configuration', response.statusText]);
    }
  });
};
