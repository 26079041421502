import { css } from '@emotion/css';
import React, { PropsWithChildren } from 'react';

import { GrafanaTheme2 } from '@grafana/data';
import { CollapsableSection, useStyles2 } from '@grafana/ui';

export interface SectionProps {
  label: string;
  isOpen: boolean;
}

export const Section = ({ label, isOpen, children }: PropsWithChildren<SectionProps>) => {
  const styles = useStyles2(getStyles);
  return (
    <div className={styles.section}>
      <CollapsableSection label={label} isOpen={isOpen}>
        {children}
      </CollapsableSection>
    </div>
  );
};

const getStyles = (theme: GrafanaTheme2) => {
  return {
    section: css`
      margin-bottom: ${theme.spacing(3)};
    `,
  };
};
