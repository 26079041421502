import React from 'react';
import { useFormContext } from 'react-hook-form';

import { Field, Input } from '@grafana/ui';

import { resourceTypeOptions } from '../constants';

import { ResourcePicker } from './ResourcePicker';
import { Section } from './Section';

export const LoginPageSection = () => {
  const { register } = useFormContext();
  return (
    <Section label={'Login page'} isOpen={true}>
      <Field
        label={'Login logo'}
        htmlFor={'loginLogo'}
        description={
          <>
            Max. file size: 3 Mb
            <br />
            Supported file type: png, jpg
          </>
        }
      >
        <ResourcePicker options={resourceTypeOptions.slice(0, -1)} name={'loginLogo'} />
      </Field>
      <Field
        label={'Login title'}
        description={
          'If you only customize the logo, the default title is not visible, unless you customize it as well.'
        }
      >
        <Input {...register('loginTitle')} id={'login-title'} />
      </Field>
      <Field
        label={'Login subtitle'}
        description={
          'If you only customize the logo, the default subtitle is not visible, \n' + 'unless you customize it as well.'
        }
      >
        <Input {...register('loginSubtitle')} id={'login-subtitle'} />
      </Field>
      <Field label={'Login background'} htmlFor={'loginBackground'}>
        <ResourcePicker options={resourceTypeOptions} name={'loginBackground'} />
      </Field>
      <Field label={'Login box background'} htmlFor={'loginBoxBackground'}>
        <ResourcePicker options={resourceTypeOptions} name={'loginBoxBackground'} />
      </Field>
    </Section>
  );
};
