import { css } from '@emotion/css';
import React, { useEffect, useState } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { connect, ConnectedProps } from 'react-redux';

import { Button, useStyles2 } from '@grafana/ui';
import { Page } from 'app/core/components/Page/Page';
import { getNavModel } from 'app/core/selectors/navModel';

import { FormPrompt } from '../../shared/FormPrompt';
import { EnterpriseStoreState } from '../../types';
import { getBrandingSettings, saveBrandingSettings } from '../api';
import { defaultSettings, dtoToConfig } from '../config';
import { BrandingSettingsDTO, ConfigData } from '../types';
import { defaultLinksChanged } from '../utils/footerLinks';

import { FooterSection } from './FooterSection';
import { LoginPageSection } from './LoginPageSection';
import { OtherSection } from './OtherSection';

function mapStateToProps(state: EnterpriseStoreState) {
  return { navModel: getNavModel(state.navIndex, 'custom-branding') };
}

const connector = connect(mapStateToProps, {});
export type Props = ConnectedProps<typeof connector>;

const defaultConfig = dtoToConfig(defaultSettings);
export const ConfigPage = ({ navModel }: Props) => {
  const styles = useStyles2(getStyles);
  const [loading, setLoading] = useState(true);
  const { handleSubmit, formState, reset, ...formMethods } = useForm<ConfigData>({
    defaultValues: defaultConfig,
  });

  useEffect(() => {
    getBrandingSettings()
      .then((settings) => reset(dtoToConfig(settings)))
      .finally(() => setLoading(false));
  }, [reset]);

  const submitData = (data: ConfigData) => {
    const formData = new FormData();
    let config = data as BrandingSettingsDTO;

    for (const [field, value] of Object.entries(data)) {
      if (value instanceof File) {
        config = { ...config, [field]: value.name };
        formData.append('files', value);
      }
    }

    // When footer links saved as null it means that default links should be shown
    if (!defaultLinksChanged(data.footerLinks, defaultConfig.footerLinks)) {
      config.footerLinks = null;
    }

    formData.append('config', JSON.stringify(config));

    saveBrandingSettings(formData, () => {
      // Reset form dirty state so the page reload is not blocked by FormPrompt
      reset(undefined, { keepValues: true });
    });
  };

  return (
    <Page navModel={navModel}>
      <Page.Contents isLoading={loading}>
        <FormProvider {...formMethods} handleSubmit={handleSubmit} reset={reset} formState={formState}>
          <form className={styles.form} onSubmit={handleSubmit(submitData)}>
            <FormPrompt onDiscard={() => {}} confirmRedirect={formState.isDirty} />
            <LoginPageSection />
            <FooterSection />
            <OtherSection />
            <Button variant={'primary'} type={'submit'}>
              Save
            </Button>
          </form>
        </FormProvider>
      </Page.Contents>
    </Page>
  );
};

const getStyles = () => {
  return {
    form: css`
      max-width: 500px;
    `,
  };
};

export default connector(ConfigPage);
